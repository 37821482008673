import React, {useEffect, useState} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {useTracking} from './useTracking';
import Home from './pages/Home';
import Contact from './pages/Contact';
import HomeHealth from './pages/HomeHealth';
import Hospice from './pages/Hospice';
import News from './pages/News';

import Footer from './components/shared/Footer';
import Navigation from './components/shared/Navigation';
import BlogArticle from './pages/BlogArticle';
import {ApiRoutes} from './apiRoutes';
import axios from 'axios';
import {Page} from '../../@shared/models/Page';
import * as constants from './constants';
import Opportunities from './pages/Opportunities';
import BrioFamily from './pages/BrioFamily';
import PageNotFound from './pages/PageNotFound';

const App: React.FC = () => {
  const [ pages, setPages ] = useState<Page[]>([]);

  useTracking();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.Pages}`);
      setPages(result.data);
    };
    fetchData();
  }, []);

  const findPageById = (pages: Page[], id: string): Page => {
    let page = pages.find(p => p.id === id);
    if (!page) {
      page = {
        id: '',
        title: '',
        subtitle: '',
        headerDescription: '',
        metaKeywords: [],
        metaDescription: '',
      };
    }
    return page;
  };

  return (
    <Switch>
      <Route exact={true} path={constants.Page.Home}>
        <Home {...findPageById(pages, constants.PageId.Home)} />
      </Route>
      <Route path={constants.Page.HomeHealth}>
        <HomeHealth {...findPageById(pages, constants.PageId.HomeHealth)} />
      </Route>
      <Route path={constants.Page.Hospice}>
        <Hospice {...findPageById(pages, constants.PageId.Hospice)} />
      </Route>
      <Route exact={true} path={constants.Page.HealthNews}>
        <News {...findPageById(pages, constants.PageId.News)} />
      </Route>
      <Route path={constants.Page.HealthNewsArticle}>
        <BlogArticle />
      </Route>
      <Route path={constants.Page.Opportunities}>
        <Opportunities {...findPageById(pages, constants.PageId.Contact)} />
      </Route>
      <Route path={constants.Page.BrioFamily}>
        <BrioFamily {...findPageById(pages, constants.PageId.BrioFamily)} />
      </Route>
      <Route path={constants.Page.Contact}>
        <Contact {...findPageById(pages, constants.PageId.Contact)} />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default () => (
  <>
    <BrowserRouter>
      <Navigation />
      <App />
    </BrowserRouter>
    <Footer />
  </>
);
