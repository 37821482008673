import React, {useState} from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import {Formik, Form, Field, ErrorMessage, FormikActions} from 'formik';
import {ApiRoutes} from '../../apiRoutes';

const AppFormValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2)
    .max(100)
    .required('First Name is required'),
  lastName: Yup.string()
    .min(2)
    .max(100)
    .required('Last Name is Required'),
  email: Yup.string()
    .email('Invalid e-mail')
    .required('E-mail is required'),
  phone: Yup.string()
    .min(6)
    .max(20)
    .required('Phone number is required'),
  address: Yup.string()
    .min(20)
    .max(300)
    .required('Address is required'),
  why: Yup.string()
    .max(5000)
    .required('Tell us why is required'),
  file: Yup.string()
    .required('Resume is required'),
  position: Yup.string()
    .required('Position is required'),
});

function Checkbox (props: any) {
  return (
    <Field name={props.name}>
      {({field, form}) => (
        <label className="w-checkbox checkbox-field">
          <input
            {...field}
            type="checkbox"
            checked={field.value.includes(props.value)}
            onChange={() => {
              const set = new Set(field.value);
              if (set.has(props.value)) {
                set.delete(props.value);
              } else {
                set.add(props.value);
              }
              field.onChange(field.name)(Array.from(set));
              form.setFieldTouched(field.name, true);
            }}
          />&nbsp;&nbsp;
          <span className="w-form-label">{props.value}</span>
        </label>
      )}
    </Field>
  );
}

type FileMeta = {
  name: string;
  type: string;
  size: number;
}
const defaultFile: FileMeta = {
  name: '',
  type: '',
  size: 0
}

const ApplicationForm: React.FC = () => {

  const [ file64, setFile64 ] = useState('');
  const [ file, setFile ] = useState<FileMeta>(defaultFile);

  const submit = async (values: any, actions: FormikActions<any>) => {
    console.log('form: ', values);
    actions.setSubmitting(false);

    const payload = {
      ...values,
      fileBase64: file64.replace('data:application/pdf;base64,', '')
    };

    try {
      await axios.post(ApiRoutes.Employment, payload);
      actions.setSubmitting(false);
      actions.setStatus({success: 'Email sent.'});
      setTimeout(() => {
        setFile64('');
        setFile(defaultFile);
        actions.resetForm();
      }, 1000);
    } catch (error) {
      actions.setStatus({error: 'Error sending the email.'});
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    let reader = new FileReader();
    let _file = e.target.files[ 0 ];
    setFile({
      name: _file.name,
      type: _file.type,
      size: _file.size
    });
    reader.onloadend = () => {
      setFile64(reader.result as string);
    };
    reader.readAsDataURL(_file);
  }

  return (
    <div className="subpage-header-2 sign-up-1">
      <div className="container-4 w-container">
        <div className="sign-up-row-2 w-row">
          <div className="sign-up-column-left w-clearfix w-col w-col-7">
            <div className="sign-up-form">
              <h3 className="contact-form-title">Employment Application Form</h3>
              <Formik
                initialValues={{firstName: '', lastName: '', email: '', phone: '', address: '', why: '', position: [], file: ''}}
                validationSchema={AppFormValidationSchema}
                onSubmit={submit}
              >
                {({errors, status, touched, isSubmitting, setFieldValue}) => (
                  <Form>
                    <div className="text-block-3">Personal Information: </div>
                    <div className="w-row">
                      <div className="field-column w-col w-col-6">
                        <Field type="text" name="firstName" className="dark-field-2 description first w-input" maxLength="256" placeholder="First Name" />
                        <ErrorMessage name="firstName" component="div" className="error-bg" />
                      </div>
                      <div className="field-column w-col w-col-6">
                        <Field type="text" name="lastName" className="dark-field-2 description w-input" maxLength="256" placeholder="Last Name" />
                        <ErrorMessage name="lastName" component="div" className="error-bg" />
                      </div>
                    </div>

                    <Field type="email" name="email" className="dark-field-2 description w-input" maxLength="256" placeholder="E-mail address" />
                    <ErrorMessage name="email" component="div" className="error-bg" />

                    <Field type="tel" name="phone" className="dark-field-2 description w-input" maxLength="256" placeholder="Telephone number" />
                    <ErrorMessage name="phone" component="div" className="error-bg" />

                    <Field type="text" name="address" className="dark-field-2 description w-input" maxLength="256" placeholder="Home Address" />
                    <ErrorMessage name="address" component="div" className="error-bg" />

                    <Field
                      type="text"
                      component="textarea"
                      name="why"
                      className="textarea-2 w-input"
                      maxLength="5000"
                      placeholder="Tell us why you want to be apart of the BRIO family."
                    />
                    <ErrorMessage name="why" component="div" className="error-bg" />

                    <div className="text-block-43">Check Desired Position</div>
                    <ErrorMessage name="position" component="div" className="error-bg" />
                    <div className="columns w-row">
                      <div className="w-col w-col-6">
                        <Checkbox name="position" value="Skilled Nurse (RN, LVN)" />
                        <Checkbox name="position" value="Office Professional" />
                        <Checkbox name="position" value="Physical Therapist" />
                        <Checkbox name="position" value="Speech Therapist" />
                        <Checkbox name="position" value="Occupational Therapist" />
                      </div>
                      <div className="w-col w-col-6">
                        <Checkbox name="position" value="Social Worker" />
                        <Checkbox name="position" value="Community Liaison" />
                        <Checkbox name="position" value="Chaplain" />
                        <Checkbox name="position" value="Volunteer" />
                        <Checkbox name="position" value="Certified Home Health Aide" />
                      </div>
                    </div>

                    <div className="text-block-3">Submit Resume</div>
                    <div className="file-upload w-file-upload">
                      <div className="w-file-upload-default">
                        <label className="button-3 w-file-upload-label">
                          <div className="w-icon-file-upload-icon"></div>
                          <div className="text w-inline-block">Upload File</div>
                          <input type="file" id="file" name="file" accept="application/pdf" data-name="File" className="w-file-upload-input"
                            //onChange={handleFileChange}
                            onChange={(event) => {
                              if (event.target.files && event.target.files.length > 0) {
                                setFieldValue("file", event.target.files[ 0 ]);
                              }
                              handleFileChange(event);
                            }}
                          />
                        </label>
                        {
                          file.name &&
                          <div><strong>{file.name}</strong></div>
                        }
                        <ErrorMessage name="file" component="div" className="error-bg" />
                        <div className="text-block-38 w-file-upload-info">PDF only. Max file size 10MB.</div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="button-2 light w-button"
                      disabled={isSubmitting}
                    >
                      Submit
                </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="sign-up-column-right w-col w-col-5">
            <div className="sign-up-info-block">
              <div className="join-main-wrapper">
                <p className="join-sub-text joinus">JOIN THE BRIO FAMILY!</p>
                <div className="join-wrapper">
                  <div className="join-title">A Wide Range of Opportunities</div>
                  <div className="text-block-44">As a growing agency, Brio Home Health &amp; Hospice has frequent career opportunities.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplicationForm;
