import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {BASE_CONTENT_URL, Page} from '../../constants';

const Navigation: React.FC = () => {
  let location = useLocation();
  return (
    <div
      data-collapse="medium"
      data-animation="over-right"
      data-duration="400"
      data-no-scroll="1"
      role="banner"
      className="navbar w-nav"
    >
      <div className="container-3 w-container">
        <nav role="navigation" className="nav-menu w-nav-menu">
          <Link
            to={Page.Home}
            title="Home"
            className={`nav-tabs light w-nav-link ${
              location.pathname === Page.Home ? 'w--current' : ''
              }`}
          >
            Home
          </Link>

          <Link
            to={Page.HomeHealth}
            title="Home Health"
            className={`nav-tabs light w-nav-link ${
              location.pathname === Page.HomeHealth ? 'w--current' : ''
              }`}
          >
            Home Health
          </Link>

          <Link
            to={Page.Hospice}
            title="Hospice"
            className={`nav-tabs light w-nav-link ${
              location.pathname === Page.Hospice ? 'w--current' : ''
              }`}
          >
            Hospice
          </Link>

          <Link
            to={Page.HealthNews}
            title="Health News"
            className={`nav-tabs light w-nav-link ${
              location.pathname === Page.HealthNews ? 'w--current' : ''
              }`}
          >
            Health News
          </Link>
          <Link
            to={Page.Opportunities}
            title="Opportunities"
            className={`nav-tabs light w-nav-link ${
              location.pathname === Page.Opportunities ? 'w--current' : ''
              }`}
          >
            Opportunities
          </Link>
          <Link
            to={Page.BrioFamily}
            title="Brio Family"
            className={`nav-tabs light w-nav-link ${
              location.pathname === Page.BrioFamily ? 'w--current' : ''
              }`}
          >
            Brio Family
          </Link>
          <Link
            to={Page.Contact}
            title="Contact"
            className={`nav-tabs light w-nav-link ${
              location.pathname === Page.Contact ? 'w--current' : ''
              }`}
          >
            Contact
          </Link>
        </nav>
        <div className="transparant-menu-button w-nav-button">
          <div className="w-icon-nav-menu"></div>
        </div>
        <img
          src={`${BASE_CONTENT_URL}/images/brio-logo.png`}
          width="100"
          id="BrioLogo"
          alt=""
          className="briologo"
        />
      </div>
    </div>
  );
};

export default Navigation;
