import React from 'react';
import ReactMarkdown from 'react-markdown';

type AttentionProps = {
  content: string;
}

const Attention: React.FC<AttentionProps> = props => {
  return (
    <div className="attention">
      <div className="text-block-37">
        <ReactMarkdown source={props.content} />
      </div>
    </div>

  );
};
export default Attention;
