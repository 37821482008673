import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';

type SectionContentProps = {
  id: string;
  headerClassName?: string;
};

const SectionContent: React.FC<SectionContentProps> = props => {
  const [ item, setItem ] = useState({
    title: '',
    content: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.SectionContent}/${props.id}`);
      setItem(result.data);
    };
    fetchData();
  }, [ props.id ]);

  return (
    <>
      <h2 className={props.headerClassName}>{item.title}</h2>
      <ReactMarkdown className="w-container hh-list" source={item.content} />
    </>
  );
};

export default SectionContent;
