import React, {ReactElement, useState, useEffect} from 'react';
import {FAQ} from '../../../../@shared/models/FAQ';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import {ApiRoutes} from '../../apiRoutes';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type FAQProps = {
  type: string;
  title: string;
  subTitle: string;
};

const FAQs: React.FC<FAQProps> = props => {
  const defaultItems: FAQ[] = [];
  const [ items, setItems ] = useState(defaultItems);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.FAQs}/${props.type}`);
      setItems(result.data);
    };
    fetchData();
  }, [ props.type ]);

  const createItem = (item: FAQ, index: number): ReactElement => {
    return (
      <ExpansionPanel key={index} className="accordion">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h1 className="heading-56">{item.question}</h1>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="accordion-item-content">

          <ReactMarkdown className="text-block-26" source={item.answer} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  return (
    <>
      <h1 className="heading-33">{props.title}</h1>
      <div className="text-block-25">{props.subTitle}</div>
      <div className="accordion-wrapper">
        {
          items && items.map((faq, index) => createItem(faq, index))
        }
      </div>
    </>
  );
};

export default FAQs;
