import React from 'react';
import HowCanWeHelp from '../components/home/HowCanWeHelp';
import {Page} from '../../../@shared/models/Page';
import Meta from '../components/shared/Meta';
import Stats from '../components/home/Stats';
import Attention from '../components/home/Attention';
import SectionContent from '../components/home/SectionContent';
import Video from '../components/home/Video';
import UpcomingEvents from '../components/home/UpcomingEvents';

const Home: React.FC<Page> = props => {
  return (
    <>
      <Meta {...props} />
      <Video />
      {
        props.attention ?
          <Attention content={props.attention.content} /> : null
      }
      <Stats />
      <HowCanWeHelp />
      <SectionContent />
      <UpcomingEvents />
    </>
  );
};

export default Home;
