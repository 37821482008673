import React from 'react';
import { Modal } from '@material-ui/core';

const Video: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="brio-family-hero w-background-video w-background-video-atom">
      <video
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        style={{
          backgroundImage:
            "url('https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5f5b00f231b6f83754dec656_The Volunteer-poster-00001.jpg')",
        }}
        data-wf-ignore="true"
        data-object-fit="cover"
      >
        <source
          src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5f5b00f231b6f83754dec656_The Volunteer-transcode.mp4"
          data-wf-ignore="true"
        />
        <source
          src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5f5b00f231b6f83754dec656_The Volunteer-transcode.webm"
          data-wf-ignore="true"
        />
      </video>
      <div className="hero-block-overlay business">
        <button
          onClick={handleOpen}
          className="hero-video-lightbox w-inline-block w-lightbox"
          style={{
            opacity: 1,
            backgroundColor: 'transparent',
            transform: 'translateX(0px) translateY(0px) translateZ(0px)',
            display: 'block',
            transition: 'opacity 500ms ease 0s, transform 500ms ease 0',
          }}
        >
          <div className="video-lightbox-button large-color home-play">
            <img
              src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5d9527abbaa15e73205b1ca3_Icon-play-white.png"
              alt="play icon"
              className="video-lightbox-icon"
            />
          </div>
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Brio video"
        aria-describedby="Brio video popup"
      >
        <div className="w-lightbox-backdrop show" tabIndex={0}>
          <div className="w-lightbox-container">
            <div className="w-lightbox-content">
              <div className="w-lightbox-view">
                <div className="w-lightbox-frame">
                  <iframe
                    className="embedly-embed w-lightbox-embed"
                    src="//player.vimeo.com/video/437269639"
                    width="400"
                    height="400"
                    scrolling="no"
                    title="Vimeo embed"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen={true}
                  ></iframe>
                </div>
              </div>
              <div
                className="w-lightbox-control w-lightbox-close"
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              ></div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Video;
