import React, {ReactElement, useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import {BusinessEntity} from '../../../../@shared/models/BusinessEntity';

const HowCanWeHelp: React.FC = () => {
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(ApiRoutes.BusinessEntities);
      setItems(result.data);
    };
    fetchData();
  }, []);

  const createBlock = (item: BusinessEntity, buttonColor?: string): ReactElement => {
    const buttonClass = `button w-button ${buttonColor}`;
    return (
      <>
        <div className="large-pricing-title price">{item.name}</div>
        <p className="paragraph-5">{item.description}</p>
        <a href={item.url} className={buttonClass}>
          VISIT NOW
        </a>
      </>
    );
  };

  return (
    <div className="section-6">
      <div className="w-container">
        <h1 className="heading-7">Home Health vs. Hospice</h1>
        <div className="brio-video brio-embed">
          <iframe title="home video" src="https://player.vimeo.com/video/413770350"
            width="100%"
            height="600px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen={true}>
          </iframe>
        </div>
      </div>
      <div className="w-container">

        {items && items.length > 1 ? (
          <div className="section-3 home-health-vs-hospice w-row">
            <div className="service-column-left w-col w-col-6">
              <div

                className="large-pricing-block"
              >
                {createBlock(items[ 0 ], 'home-health-button')}
              </div>
            </div>
            <div className="service-column-right w-col w-col-6">
              <div
                className="large-pricing-block _2"
              >
                {createBlock(items[ 1 ], 'green')}
              </div>
            </div>
          </div>
        ) : null}

      </div>
    </div>
  );
};

export default HowCanWeHelp;
