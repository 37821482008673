import React, {ReactElement, useState, useEffect} from 'react';
import {Service} from '../../../../@shared/models/Service';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import ReactMarkdown from 'react-markdown';

const Services: React.FC = () => {
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      const result = await axios.get(ApiRoutes.Services);
      setItems(result.data);
    };
    fetchServices();
  }, []);

  const createServiceBlock = (item: Service): ReactElement => {
    return (
      <div className="feature-info-block">
        <img src={item.iconUrl} alt={item.name} className="hh-icon" />
        <div className="feature-info-title">{item.name}</div>
        <ReactMarkdown
          className="feature-info-paragraph"
          source={item.description}
        />
      </div>
    );
  };

  const createServices = (items: Service[]): ReactElement[] => {
    const groupSize = 4;
    const rows = items
      .map(item => {
        return (
          <div key={item.name} className="feature-info-column w-col w-col-3">
            {createServiceBlock(item)}
          </div>
        );
      })
      .reduce((r: any[], item, index) => {
        index % groupSize === 0 && r.push([]);
        r[ r.length - 1 ].push(item);
        return r;
      }, [])
      .map((row, index) => {
        return (
          <div key={index} className="feature-info-row w-row">
            {row}
          </div>
        );
      });
    return rows;
  };

  return (
    <div className="image-section">
      <div className="hh-section-2-bg color">
        <div className="section-title-wrapper" >
          <h2 className="title" >Wide Range of Services</h2>
          <div className="section-description">We handpick skilled healthcare professionals who are in the industry for the right reason-to care for patients and improve their lives.
        </div>

          {createServices(items)}
        </div>
      </div>
      <div className="hh-section-2-bgimage"></div>
    </div>
  );
};

export default Services;
