import React from 'react';

const PageNotFound: React.FC = () => {
  return (
    <>
      <div className="page-not-found">
        <h1>Page Not Found</h1>
        <h2>The page you are looking for was not found.</h2>
      </div>
    </>
  );
};

export default PageNotFound;
