import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';

const sectionId = "2RwfiMbdOhjKWgJyCMtZ0f"
const SectionContent: React.FC = props => {
  const [ item, setItem ] = useState({
    title: '',
    content: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.SectionContent}/${sectionId}`);
      setItem(result.data);
    };
    fetchData();
  }, []);

  return (
    <div className="section-11">
      <div className="w-container">
        <h1 className="heading-20">
          {item.content}</h1>
      </div>
    </div>
  );
};

export default SectionContent;
