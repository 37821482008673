import React from 'react';

type FeatureProps = {
  title: string;
  subtitle: string;
};

const Feature: React.FC<FeatureProps> = props => {
  return (
    <div className="home-health-header blog-1">
      <div className="section-2-content health-news w-container">
        <h2 className="health-news-title">
          {props.title}
        </h2>
        <div className="health-news-subtitle">
          {props.subtitle}
        </div>
      </div>
    </div>
  );
};

export default Feature;
