export const BASE_CONTENT_URL =
  'https://brio-web.s3-us-west-2.amazonaws.com/website';

export const TEAM_SIZE = 4;
export const RECOMMENDED_BLOG_SIZE = 3;
export const BLOG_CONTENT_MAXSIZE = 20;
export const BLOG_PAGE_SIZE = 6;
export const GA_TRACKING_ID = 'UA-170136560-1';

export enum EventType {
  Event = 'Event',
  Celebration = 'Celebration',
  BrioFamilyAnnouncement = 'Brio Family Announcement'
}

export enum Page {
  Home = '/',
  Contact = '/contact',
  Opportunities = '/contact/employement',
  BrioFamily = '/briofamily',
  HomeHealth = '/home-health',
  Hospice = '/hospice',
  HealthNews = '/health-news',
  HealthNewsArticle = '/health-news/:slug',
}

export enum PageId {
  Home = 'wjfQeOxqaBNuk41GbiUnh',
  Contact = '24AOaV8yEveNf7VLProKmn',
  HomeHealth = '4X05WrwYeb4nYIQ62NW1J6',
  Hospice = '1OVytuhtVv1VazaNIKiDr3',
  News = '5UttrPzgPyv3wrJjohT27N',
  BrioFamily = '1g83ty2hZtkYTNyYvxptPp',
}
