import React from 'react';
import {Page} from '../../../@shared/models/Page';
import Meta from '../components/shared/Meta';
import MeetTheTeam from '../components/briofamily/MeetTheTeam';
import Video from '../components/briofamily/Video';
import Announcements from '../components/briofamily/Announcements';

const BrioFamily: React.FC<Page> = props => {
  return (
    <>
      <Meta {...props} />
      <Video />
      <MeetTheTeam />
      <Announcements />
      <div className="section-17">
        <div className="html-embed w-embed w-script">
          <div className="elfsight-app-26915330-61c5-4287-a5ba-cdae9be8b331"></div>
        </div>
      </div>
    </>
  );
};

export default BrioFamily;
