import React from 'react';


const Process: React.FC = () => {
  return (
    <div>
      <div className="div-block-24">
        <h1 className="home-health-process" >
          Home Health Process</h1>
        <div className="home-health-columns hospital w-row">
          <div className="column-16 w-clearfix w-col w-col-6">
            <div className="div-block-25">
              <img src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5eb5e66fa1192866e4fa8491_hospital.png" alt="hospital" className="hh-hospital-vector"
              />
            </div>
          </div>
          <div className="column-18 w-col w-col-6" >
            <h1 className="heading-53" >1. Hospital and Doctor's Office</h1>
            <div className="text-block-41">Patient who is temporarily home bound starts at either the hospital or the Dr. Office.
                </div></div></div>
        <div className="home-health-columns agency w-row">
          <div className="column-16 w-col w-col-6"><div className="div-block-26">
            <img src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5eb5e67f3db34c8b87219c2f_brio.png" alt="brio office"
              className="hh-agency-vector" /></div></div>
          <div className="column-19 w-col w-col-6"
          ><h1 className="heading-51" >2. Brio's Office</h1>
            <div className="text-block-39" >Doctor refers patient to Home Health Services (Brio Agency)</div></div>
        </div><div className="home-health-columns patient w-row">
          <div className="column-16 w-col w-col-6"><div className="div-block-27 w-clearfix">
            <img src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5eb5e6a1042e7485fb04236c_home.png" alt="home"
              className="hh-house-vector" />
          </div>
          </div>
          <div className="column-20 w-col w-col-6" >
            <h1 className="heading-54" >3. Patients Home</h1>
            <div className="text-block-42">
              Agency sends skilled staff to patient's home for an Initial Assessment Upon Initial Assessment of the Registered Nurse (RN) or
              Physical Therapist (PT), it is determined who will visit the patient. It may be a Skilled Nurse such as a Licensed Vocational
              Nurse (MSW), PT, Occupational Therapist (OT), Speech Therapist (ST), or Dietitian. The multidisciplinary team along with the
                             Medical Director determines the plan of care such as visit frequency and length, treatment, equipment and supplies. </div>
          </div></div><div className="home-health-columns individual w-row">
          <div className="column-16 w-col w-col-6"><div className="div-block-28">
            <img src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5eb5e6b6fb1f1e881faf1afa_grandparents.png" height=""
              alt="grand parents" className="image-13" /></div></div>
          <div className="column-21 w-clearfix w-col w-col-6"
          ><h1 className="heading-52" >4. Patient becomes<br />independent again</h1>
            <div className="text-block-40">Once the plan of care is completed and goals attained, patient may be discharged from home
                                 health services. Depending on patient's condition, may return to independent Activities of Daily Living (ADLs) </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;