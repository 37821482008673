import React from 'react';
import {Page} from '../.././../@shared/models/Page';
import Meta from '../components/shared/Meta';
import ApplicationForm from '../components/opportunities/ApplicationForm';

const Opportunities: React.FC<Page> = props => {
  return (
    <>
      <Meta {...props} />
      <ApplicationForm />
    </>
  );
};

export default Opportunities;
