import React from 'react';

const Video: React.FC = () => {
  return (
    <div className="section-21">
      <div className="container-13 w-container">
        <div className="video brio-video brio-embed">
          <iframe
            title="brio video"
            src="https://player.vimeo.com/video/463242730"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen={true}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Video;
