import React from 'react';
import SubscribeToOurNewsletter from './SubscribeToOurNewsletter';
import { BASE_CONTENT_URL } from '../../constants';

const Footer: React.FC = () => {
  return (
    <div className="footer section-15">
      <SubscribeToOurNewsletter />
      <div className="section-2-content footer-container w-container">
        <div className="footer-row block w-row">
          <div className="footer-column w-col w-col-6">
            <div className="footer-title">
              <span className="text-span-3">BRIO</span>
            </div>
            <p className="footer-paragraph">
              ‍
              <a href="tel:9096064415" className="link-2">
                Office: 909.606.4415 <br />
              </a>
              <a href="tel:9096064430" className="link">
                Fax: 909.606.4430
              </a>
              <br />
            </p>
          </div>
          <div className="footer-column w-col w-col-6">
            <a
              href="https://www.facebook.com/BrioHHH/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon fb w-inline-block"
            >
              <img
                src={`${BASE_CONTENT_URL}/images/icon-facebook_o.png`}
                alt="facebook"
                className="footer-small-social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/briocares/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon ig w-inline-block"
            >
              <img
                src={`${BASE_CONTENT_URL}/images/icon-instagram_o.png`}
                alt="instagram"
                className="footer-small-social-icon"
              />
            </a>
            <a
              href="https://www.yelp.com/biz/brio-home-health-and-hospice-chino-hills"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-icon yelp w-inline-block"
            >
              <img
                src={`${BASE_CONTENT_URL}/images/icon-yelp_o.png`}
                alt="yelp"
                className="footer-small-social-icon"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="copy-right-tag w-container">
        <p className="bottom-footer-paragraph">
          <strong data-new-link="true" className="bold-text-3">
            Copyright © Brio Home Health &amp;&nbsp;Brio Hospice
          </strong>
        </p>
        <p className="bottom-footer-paragraph right">
          Providing Personalized Care to Clients Since 2006
        </p>
      </div>
    </div>
  );
};

export default Footer;
