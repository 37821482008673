import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';

const sectionId = "01k826g2oMkuEm9ctL7Ydz"
const SectionContent1: React.FC = props => {
  const [ item, setItem ] = useState({
    title: '',
    content: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.SectionContent}/${sectionId}`);
      setItem(result.data);
    };
    fetchData();
  }, []);

  return (
    <div className="section-14">
      <div className="w-container">
        <div className="hospice-content-2 consider">
          <h1 className="hospice-considering-header" >{item.title}</h1>
          <p className="paragraph-16">
            {item.content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionContent1;
