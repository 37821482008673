import React from 'react';
import Feature from '../components/homehealth/Feature';
import Qualifications from '../components/homehealth/Qualifications';
import SectionContent from '../components/shared/SectionContent';
import {Page} from '../../../@shared/models/Page';
import Meta from '../components/shared/Meta';
import Process from '../components/homehealth/Process';
import Services from '../components/homehealth/Services';
import FAQs from '../components/shared/FAQs';

const HomeHealth: React.FC<Page> = props => {
  return (
    <>
      <Meta {...props} />
      <Feature title={props.title} description={props.headerDescription} />
      <section className="hh-section-2">
        <SectionContent id="UQffBfdxudfB5w9wK3IMV" headerClassName="heading-35" />
      </section>
      <Process />
      <Qualifications
        title="Who qualifies for Home Health in California?"
        type="Home Health"
      />
      <Services />
      <div className="hh-section-accordion">
        <div className="w-container">
          <div className="hh-questions hh-questions-image"></div>
          <FAQs title="Frequently Asked Questions" subTitle="For Well-Informed Healthcare Decisions" type="Home Health" />
        </div>
      </div>
    </>
  );
};

export default HomeHealth;
