export enum ApiRoutes {
  Services = '/api/services',
  BusinessEntities = '/api/businessEntities',
  Team = '/api/team',
  Qualifications = '/api/qualifications',
  FAQs = '/api/faqs',
  SectionContent = '/api/content',
  SectionContents = '/api/contents',
  ServiceFeature = '/api/servicefeature',
  ServicesFull = '/api/servicesfull',
  Blogs = '/api/blogs',
  Events = '/api/events',
  Pages = '/api/pages',
  ContactUs = '/api/contactus',
  Employment = '/api/employement',
}
