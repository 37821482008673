import React from 'react';

type FeatureProps = {
  title: string;
  description: string;
};

const Feature: React.FC<FeatureProps> = props => {
  return (
    <div className="sub-header-hospice">
      <div className="hhwhitebg">
        <h1 className="heading-28"
          style={{
            opacity: 1,
            transform: "translateX(0px) translateY(0px) translateZ(0px)",
            display: "block",
            transition: "opacity 500ms ease 0s, transform 500ms ease 0s"
          }}
        >{props.title}
        </h1>
        <div className="text-block-21 hh-subtext"
          style={{
            opacity: 1,
            display: "block",
            transition: "opacity 500ms ease 0s"
          }}>
          {props.description}
        </div>
      </div>
      <div className="hh-hero-bgimage">
      </div>
    </div>
  );
};

export default Feature;