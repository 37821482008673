import React, {ReactElement, useState, useEffect} from 'react';
import {Qualification} from '../../../../@shared/models/Qualification';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import {ApiRoutes} from '../../apiRoutes';

type QualificationProps = {
  type: string;
  title: string;
};

const Qualifications: React.FC<QualificationProps> = props => {
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.Qualifications}/${props.type}`);
      setItems(result.data);
    };
    fetchData();
  }, [ props.type ]);

  const createItem = (item: Qualification): ReactElement => {
    return (
      <>
        <div className="div-block-9">
          <img src={item.imageUrl} alt={item.title} className="physician-image" />
          <h3 className="heading-30">{item.title}</h3>
        </div>
        <ReactMarkdown className="paragraph-11" source={item.description} />
      </>
    );
  };

  const createRow = (items: Qualification[]): ReactElement[] => {
    const groupSize = 3;
    const rows = items
      .map((item, index) => {
        let columnClass = "column-4";
        if (index === 1) {
          columnClass = "column-5";
        }
        return (
          <div key={item.title} className={`${columnClass} w-col w-col-4`} >
            {createItem(item)}
          </div >
        );
      })
      .reduce((r: any[], item, index) => {
        index % groupSize === 0 && r.push([]);
        r[ r.length - 1 ].push(item);
        return r;
      }, [])
      .map((row, index) => {
        return (
          <div key={index} className="hh-heros w-row">
            {row}
          </div>
        );
      });
    return rows;
  };

  return (
    <div className="section-13">
      <h1>
        <strong className="hh-section-1-heading">{props.title}</strong>
      </h1>
      {createRow(items)}
    </div>
  );
};

export default Qualifications;
