import React from 'react';
import {Formik, Field, Form, ErrorMessage, FormikActions} from 'formik';
import * as Yup from 'yup';
import {Contact} from '../../../../@shared/models/Contact';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';

type Props = {
  title: string;
  subtitle: string;
} & Contact;

const ContactUsValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2)
    .max(70)
    .required('Name is required'),
  email: Yup.string()
    .email()
    .required('E-mail is required'),
  message: Yup.string()
    .min(10)
    .max(1000)
    .required('Message is required'),
});

const ContactUs: React.FC<Props> = props => {
  const submit = async (values: any, actions: FormikActions<any>) => {
    try {
      await axios.post(ApiRoutes.ContactUs, values);
      actions.setSubmitting(false);
      actions.setStatus({success: 'Email sent.'});
      setTimeout(() => {
        actions.resetForm();
      }, 1000);
    } catch (error) {
      actions.setStatus({error: 'Error sending the email.'});
    }
  };

  return (
    <div className="home-health-header contact-1">
      <div className="contact w-container">
        <div className="white-contact-form">
          <Formik
            initialValues={{name: '', email: '', message: ''}}
            validationSchema={ContactUsValidationSchema}
            onSubmit={submit}
          >
            {({errors, status, touched, isSubmitting}) => (
              <Form>
                <h1 className="form-title">{props.title}</h1>
                {props.subtitle ? (
                  <h2 className="form-title subtitle">{props.subtitle}</h2>
                ) : (
                    <></>
                  )}
                {status && status.error ? (
                  <h3 className="error-bg">{status.error}</h3>
                ) : (
                    ''
                  )}
                {status && status.success ? (
                  <h3 className="success-bg">{status.success}</h3>
                ) : (
                    ''
                  )}
                <label className="field-label">Name:</label>
                <Field type="text" name="name" className="field rounded w-input" />
                <ErrorMessage name="name" component="div" className="error-bg" />

                <label className="field-label">Email address:</label>
                <Field type="email" name="email" className="field rounded w-input" />
                <ErrorMessage name="email" component="div" className="error-bg" />

                <label className="field-label">Your message:</label>
                <Field
                  type="text"
                  component="textarea"
                  name="message"
                  className="field rounded area w-input"
                />
                <ErrorMessage name="message" component="div" className="error-bg" />
                <button
                  type="submit"
                  className="button w-button"
                  disabled={isSubmitting}
                >
                  Send Email
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="contact-info-block-right">
          <div className="contact-info-block">
            {/* <div className="contact-info-small-block">
              <div className="small-contact-info-title">Email</div>
              <p className="contact-info-description">{props.email}</p>
            </div> */}
            <div className="contact-info-small-block">
              <div className="small-contact-info-title">Phone numbers</div>
              <p className="contact-info-description">Office #: {props.phone}
                <br />
              Fax #: {props.fax}</p>
            </div>
            <div className="contact-info-small-block">
              <div className="small-contact-info-title">Our Location</div>
              <p className="contact-info-description">
                {props.address}
                <br />
                {props.city}, {props.state} {props.zip}
              </p>
            </div>
            <div className="contact-info-small-block">
              <div className="small-contact-info-title">Support</div>
              <div className="contact-info-description">
                <ReactMarkdown source={props.support} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
