import React, {useState, useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import {useParams} from 'react-router-dom';
import {ApiRoutes} from '../apiRoutes';
import axios from 'axios';
import moment from 'moment';
import Recommended from '../components/blog/Recommended';
import Meta from '../components/shared/Meta';
import removeMd from 'remove-markdown';
import {BLOG_CONTENT_MAXSIZE} from '../constants';

const BlogArticle: React.FC = () => {
  let {slug} = useParams();
  const [ item, setItem ] = useState({
    id: '',
    title: '',
    slug: '',
    date: undefined,
    content: '',
    imageUrl: '',
  });
  const [ headerStyle, setHeaderStyle ] = useState({
    backgroundImage: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.Blogs}/${slug}`);
      setItem(result.data);
      setHeaderStyle({
        backgroundImage: `url(${result.data.imageUrl})`,
      });
    };
    fetchData();
  }, [ slug ]);
  return (
    <>
      <Meta
        title={item.title}
        metaKeywords={[]}
        metaDescription={removeMd(item.content).substring(0, BLOG_CONTENT_MAXSIZE)}
      />
      <div className="blog-post-header" style={headerStyle}>
        <div className="blog-post-header-overlay">
          <div className="features w-container">
            <div className="blog-single-post-date">
              {moment(item.date).format('MMMM DD, YYYY')}
            </div>
            <h2 className="subpage-title blog-post-title">
              {item.title}
            </h2>
          </div>
        </div>
      </div>
      <div className="section-1 blog-page-section">
        <div className="section-2-content w-container">
          <div className="blog-post-page-content">
            <div className="rich-text-block w-richtext">
              <ReactMarkdown source={item.content} />
            </div>
          </div>
          <Recommended id={item.id} />
        </div>
      </div>
    </>
  );
};

export default BlogArticle;
