import React from 'react';
import Feature from '../components/news/Feature';
import Articles from '../components/news/Articles';
import {Page} from '../../../@shared/models/Page';
import Meta from '../components/shared/Meta';
import Video from '../components/news/Video';
import Attention from '../components/news/Attention';

const News: React.FC<Page> = props => {
  return (
    <>
      <Meta {...props} />
      <Feature title={props.title} subtitle={props.subtitle} />
      {
        props.attention ?
          <Attention content={props.attention.content} modal={props.attention.modal} /> : null
      }
      <Video />
      <Articles />
    </>
  );
};

export default News;
