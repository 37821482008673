import React, {ReactElement, useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import {RECOMMENDED_BLOG_SIZE, BLOG_CONTENT_MAXSIZE, Page, BLOG_PAGE_SIZE} from '../../constants';
import {Blog, Blogs, MetaCollection} from '../../../../@shared/models/Blog';
import moment from 'moment';
import removeMd from 'remove-markdown';
import {trimByWords} from '../../util';

const pageSize = BLOG_PAGE_SIZE;

const Articles: React.FC = () => {
  const [ items, setItems ] = useState<Blog[]>([]);
  const [ pageNumber, setPage ] = useState(1);
  const [ meta, setMeta ] = useState<MetaCollection>();

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchBlogs(pageNumber, pageSize);
      setItems(result.items);
      delete result.items;
      setMeta({
        ...result
      });
      setPage(pageNumber);
    };
    fetchData();
  }, [ pageNumber ]);

  const fetchBlogs = async (page: number, size: number) => {
    const skip = (page - 1) * size;
    const response = await axios.get(`${ApiRoutes.Blogs}?limit=${size}&skip=${skip}`);
    const result: Blogs = response.data;
    return {
      ...result,
      hasNextPage: (page * size < result.total),
      hasPreviousPage: page > 1
    }
  }

  const createItem = (item: Blog): ReactElement => {
    const content = removeMd(item.content);
    return (
      <>
        <a
          href={`${Page.HealthNews}/${item.slug}`}
          className="simple-blog-image-block w-inline-block"
          style={{
            backgroundImage: `url(${item.imageUrl})`,
          }}
        >
          <div className="blog-post-overlay light"></div>
        </a>
        <div className="blog-post-date">
          {moment(item.date).format('MMMM DD, YYYY')}
        </div>
        <a href={`${Page.HealthNews}/${item.slug}`} className="blog-post-title-link">
          {item.title}
        </a>
        <p className="blog-post-summary">
          {
            trimByWords(content, BLOG_CONTENT_MAXSIZE)
          }
        </p>
      </>
    );
  };

  const createItemsRow = (items: Blog[]): ReactElement[] => {
    const groupSize = RECOMMENDED_BLOG_SIZE;
    const rows = items
      .map(item => {
        return (
          <div
            key={item.title}
            className="simple-blog-post-item w-dyn-item w-col w-col-4"
          >
            {createItem(item)}
          </div>
        );
      })
      .reduce((r: any[], item, index) => {
        index % groupSize === 0 && r.push([]);
        r[ r.length - 1 ].push(item);
        return r;
      }, [])
      .map((row, index) => {
        return (
          <div key={index} className="blog-posts-list w-clearfix w-dyn-items w-row">
            {row}
          </div>
        );
      });
    return rows;
  };

  return (
    <div className="section-1">
      <div className="section-2-content w-container">
        <div className="blog-posts-list-wrapper w-dyn-list">
          {createItemsRow(items)}
          <div className="w-pagination-wrapper">
            {
              meta && meta.hasPreviousPage ? (
                <button type="button" className="w-pagination-previous" onClick={() => {setPage(pageNumber - 1)}}>
                  <svg className="w-pagination-previous-icon" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                    <path fill="none" stroke="currentColor" fillRule="evenodd" d="M8 10L4 6l4-4"></path>
                  </svg>
                  <div className="w-inline-block">Previous</div>
                </button>
              ) : null
            }
            {
              meta && meta.hasNextPage ? (
                <button type="button" className="w-pagination-next" onClick={() => {setPage(pageNumber + 1)}}>
                  <div className="w-inline-block">Next</div>
                  <svg className="w-pagination-next-icon" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                    <path fill="none" stroke="currentColor" fillRule="evenodd" d="M4 2l4 4-4 4"></path>
                  </svg>
                </button>) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
