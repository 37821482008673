import React, {ReactElement, useState, useEffect} from 'react';
import {Team} from '../../../../@shared/models/Team';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import {TEAM_SIZE} from '../../constants';

const MeetTheTeam: React.FC = () => {
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(ApiRoutes.Team);
      setItems(result.data);
    };
    fetchData();
  }, []);

  const createTeamMember = (item: Team): ReactElement => {
    return (
      <div>
        <img
          width="200"
          src={item.thumbnailUrl}
          alt={item.name}
          className="image-7"
        />
        <div className="team-name">{item.name}</div>
        <div className="text-block-45">{item.title}</div>
      </div>
    );
  };

  const createTeam = (items: Team[]): ReactElement[] => {
    const groupSize = TEAM_SIZE;
    const rows = items
      .map(item => {
        return (
          <div key={item.name} role="listitem" className="collection-item-6 w-dyn-item w-col w-col-4">
            {createTeamMember(item)}
          </div>
        );
      })
      .reduce((r: any[], item, index) => {
        index % groupSize === 0 && r.push([]);
        r[ r.length - 1 ].push(item);
        return r;
      }, [])
      .map((row, index) => {
        return (
          <div key={index} role="list" className="brio-team-list w-dyn-items w-row">
            {row}
          </div>
        );
      });
    return rows;
  };

  return (
    <div className="meet-the-team">
      <div className="section-2-content w-container">
        <h2 className="subpage-title">Meet the Team</h2>
      </div>
      <div className="div-block-14">
        <div className="brio-team w-dyn-list">
          {createTeam(items)}
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeam;
