import React, {ReactElement, useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import {RECOMMENDED_BLOG_SIZE, Page, BLOG_CONTENT_MAXSIZE} from '../../constants';
import {Blog} from '../../../../@shared/models/Blog';
import moment from 'moment';
import removeMd from 'remove-markdown';
import {trimByWords} from '../../util';

type Props = {
  id: string;
};

const Recommended: React.FC<Props> = props => {
  const [ items, setItems ] = useState<Blog[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${ApiRoutes.Blogs}?limit=3&excludeId=${props.id}`,
      );
      setItems(result.data.items);
    };
    fetchData();
  }, [ props.id ]);

  const createItem = (item: Blog): ReactElement => {
    const content = removeMd(item.content);
    return (
      <>
        <a
          href={`${Page.HealthNews}/${item.slug}`}
          className="simple-blog-image-block w-inline-block"
          style={{
            backgroundImage: `url(${item.imageUrl})`,
          }}
        >
          <div className="blog-post-overlay light"></div>
        </a>
        <div className="blog-post-content">
          <div className="blog-post-date">
            {moment(item.date).format('MMMM DD, YYYY')}
          </div>
          <a href={`${Page.HealthNews}/${item.slug}`} className="blog-post-title-link">
            {item.title}
          </a>
          <p className="blog-post-summary">
            {
              trimByWords(content, BLOG_CONTENT_MAXSIZE)}
          </p>
        </div>
      </>
    );
  };

  const createItemsRow = (items: Blog[]): ReactElement[] => {
    const groupSize = RECOMMENDED_BLOG_SIZE;
    const rows = items
      .map(item => {
        return (
          <div
            key={item.title}
            className="simple-blog-post-item related w-dyn-item w-col w-col-4"
          >
            {createItem(item)}
          </div>
        );
      })
      .reduce((r: any[], item, index) => {
        index % groupSize === 0 && r.push([]);
        r[ r.length - 1 ].push(item);
        return r;
      }, [])
      .map((row, index) => {
        return (
          <div
            key={index}
            className="blog-posts-list related w-clearfix w-dyn-items w-row"
          >
            {row}
          </div>
        );
      });
    return rows;
  };

  return (
    <>
      <div className="section-title-wrapper">
        <h2 className="title dont-miss">Don't miss these stories:</h2>
      </div>

      <div className="blog-posts-list-wrapper w-dyn-list">
        {createItemsRow(items)}
      </div>
    </>
  );
};

export default Recommended;
