import React from 'react';
import MapOfOffice from '../components/contact/MapOfOffice';
import ContactUs from '../components/contact/ContactUs';
import { Page } from '../../../@shared/models/Page';
import Meta from '../components/shared/Meta';

const Contact: React.FC<Page> = props => {
  return (
    <>
      <Meta {...props} />
      <ContactUs title={props.title} subtitle={props.subtitle} {...props.related} />
      <MapOfOffice />
    </>
  );
};

export default Contact;
