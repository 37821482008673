import React from 'react';

const SubscribeToOurNewsletter: React.FC = () => {
  return (
    <div className="section-title-wrapper full-centered">
      <h2 className="title footer-title">Subscribe</h2>
      <div className="section-description subtitle">
        Get informed by joining our mailing list.
      </div>
      <div className="contact-form-2 w-form">
        <form
          action="https://Briocares.us4.list-manage.com/subscribe/post?u=55647617bb73adac99673e08f&amp;id=31f49c3183" method="post"
          id="wf-form-Newsletter-Form"
          name="wf-form-Newsletter-Form"
          data-name="Newsletter Form"
        >
          <div className="columns-4 w-row">
            <div className="w-col w-col-8">
              <input
                type="email"
                id="email"
                name="email"
                data-name="email"
                placeholder="Enter your email address"
                maxLength={256}
                required={true}
                aria-label="email address"
                className="subscribe email w-input"
              />
            </div>
            <div className="column-9 w-col w-col-4">
              <input
                aria-label="Subscribe"
                type="submit"
                value="Send Email"
                data-wait="Please wait..."
                className="button-2 submit-newsletter w-button"
              />
            </div>
          </div>
          <div id="mce-responses">
            <div id="mce-error-response" style={{display: "none"}}></div>
            <div id="mce-success-response" style={{display: "none"}}></div>
          </div>
          <div style={{
            position: "absolute",
            left: "-5000px"
          }} aria-hidden="true">
            <input type="text" aria-hidden="true" aria-label="mailchimp hidden" name="b_55647617bb73adac99673e08f_31f49c3183" tabIndex={-1} />
          </div>
        </form>
        <div className="success-message w-form-done">
          <p>Thank you! Your submission has been received!</p>
        </div>
        <div className="error-bg-2 w-form-fail">
          <p>Oops! Something went wrong while submitting the form</p>
        </div>
      </div>
    </div>
  );
};

export default SubscribeToOurNewsletter;
