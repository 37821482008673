import React from 'react';

const Video: React.FC = () => {
  return (
    <div className="section-20 health-news">
      <div className="container-12 w-container">
        <div
          className="brio-video brio-embed">
          <iframe className="embedly-embed"
            src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F413859534%3Fapp_id%3D122963&amp;dntp=1&amp;display_name=Vimeo&amp;url=https%3A%2F%2Fvimeo.com%2F413859534&amp;image=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F886674385_1280.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=vimeo"
            scrolling="no"
            title="Vimeo embed"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen={true}>
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default Video;
