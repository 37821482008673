import React, {ReactElement, useState, useEffect} from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import {ApiRoutes} from '../../apiRoutes';
import {ServiceFull} from '../../../../@shared/models/ServiceFull';

const Services: React.FC = props => {
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.ServicesFull}`);
      setItems(result.data);
    };
    fetchData();
  }, []);

  const createItem = (item: ServiceFull, index: number): ReactElement => {
    return (
      <div key={index} className="w-slide" >
        <h1 className="heading-39" >{item.title}</h1>
        <div className="container-11 w-container">
          <ReactMarkdown className="text-block-26 hospice-service" source={item.description} />
        </div>
      </div>
    );
  };

  return (
    <div className="hospice-slider">
      <div data-delay="3500"
        data-animation="slide"
        data-autoplay="1"
        data-easing="ease-in-out-sine"
        data-nav-spacing="14"
        data-duration="1000"
        data-infinite="1"
        className="slider-4 w-slider">
        <div className="w-slider-mask">
          {
            items && items.map((item, index) => createItem(item, index))
          }
        </div>
      </div>
    </div>
  );
};

export default Services;
