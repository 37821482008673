import React, {ReactElement, useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import {EventType} from '../../constants';
import moment from 'moment';
import {BrioEvent} from '../../../../@shared/models/Event';
import Carousel from 'react-material-ui-carousel'

const UpcomingEvents: React.FC = () => {
  const [ items, setItems ] = useState<BrioEvent[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${ApiRoutes.Events}?minDate=${moment().subtract(4, "month").format("YYYY-MM-DD")}`,
      );
      setItems(result.data);
      //window.Webflow?.ready();
    };
    fetchData();
  }, []);

  const createUpcomingEvent = (item: BrioEvent, index): ReactElement => {
    const dateFormat = moment(item.date).format('MMMM DD, YYYY');
    return (
      <div key={index} role="listitem" className="collection-item-2 w-dyn-item w-col w-col-4">
        <img width="323" src={item.imageUrl} alt={`upcoming events: ${item.title} on ${dateFormat}`} className="image-5" />
        <h3 className="heading-9">{item.title}</h3>
        <p className="paragraph-10">
          {dateFormat}
        </p>
        <div className="text-block-9 w-dyn-bind-empty"></div>
        <div className="text-block-11">
          {item.description}
        </div>
      </div>
    );
  };

  const createUpcomingEvents = (items: BrioEvent[]): ReactElement => {
    if (!items) {
      return (<div></div>);
    }

    const events = items.filter(i => i.type === EventType.Event)
      .slice(0, 4)
      .sort(function(a, b) {
        return moment(a.date).unix() - moment(b.date).unix();
      });
    return (
      <>
        {events.map((event, index) => createUpcomingEvent(event, index))}
      </>
    )//;
  }

  const createCelebrationEvent = (item: BrioEvent, index): ReactElement => {
    return (
      <div key={index} className="">
        <h1 className="heading-21">{item.title}</h1>
        <div className="text-block-14">{item.description}</div>
      </div>
    );
  };

  const createCelebrationEvents = (items: BrioEvent[]): ReactElement => {
    if (!items) {
      return (<div></div>);
    }

    const events = items.filter(i => i.type === EventType.Celebration)
      .sort(function(a, b) {
        return moment(a.date).unix() - moment(b.date).unix();
      });
    return (
      <Carousel autoPlay={true} interval={4000} animation="slide" >
        {events.map((event, index) => createCelebrationEvent(event, index))}
      </Carousel>
    );
  }

  return (
    <>
      <div className="section-9 upcoming-events">
        <h2 className="brio-event-heading">Upcoming events</h2>
        <div className="collection-list-wrapper-2 w-dyn-list">
          <div role="list" className="collection-list w-dyn-items w-row">
            {
              createUpcomingEvents(items)
            }
          </div>
        </div>
      </div>

      <div className="section-5">
        <div className="hh-section-2-bg special">
          <div className="slider-3 w-slider">
            {
              createCelebrationEvents(items)
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingEvents;
