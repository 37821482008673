import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import ReactMarkdown from 'react-markdown';

const sectionIds = [ "3LvU6JzLnFVfyoEA3jEcCB", "2ajZFwxlf5cBVNLJc4QnVp" ]
const SectionContent2: React.FC = props => {
  const [ items, setItems ] = useState([ {
    title: '',
    content: '',
  },
  {
    title: '',
    content: '',
  } ]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${ApiRoutes.SectionContents}/${sectionIds.join(",")}`);
      setItems(result.data);
    };
    fetchData();
  }, []);

  return (
    <>
      <div>
        <div className="columns-3 w-row">
          <div className="hospice-section-2-image w-col w-col-6 w-col-stack">
            <div className="hospice-content-2 hospice-column-2">
              <h1 className="heading-37 hopsice-header-1" >
                {items[ 1 ].title}</h1>
              <ReactMarkdown className="hospice-section-2" source={items[ 1 ].content} />
            </div>
          </div>
          <div className="hopsice-section-2-content hospice-image-2 w-col w-col-6 w-col-stack">
          </div>
        </div>
      </div>
      <div>
        <div className="columns-3 volunteer w-row">
          <div className="hospice-section-2-image w-col w-col-6 w-col-stack w-col-small-small-stack w-col-tiny-tiny-stack">
            <div className="hospice-content-2">
              <h1 className="heading-37"  >{items[ 0 ].title}</h1>
              <ReactMarkdown className="hospice-section-2" source={items[ 0 ].content} />
            </div>
          </div>
          <div className="hopsice-section-2-content hopsice-image-3 w-col w-col-6 w-col-stack w-col-small-small-stack w-col-tiny-tiny-stack">
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionContent2;
