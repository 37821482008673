import React from 'react';
import {Modal} from '@material-ui/core';


type AttentionProps = {
  content: string;
  modal: any;
}

const Attention: React.FC<AttentionProps> = props => {
  const [ open, setOpen ] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getBody = () => {
    return {__html: props.modal}
  };


  return (
    <>
      <div className="section-22">
        <div className="container-14 w-container" style={{cursor: "pointer"}}>
          <div className="text-block-46" onClick={handleOpen}>{props.content}</div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="popup-brio">
          <div data-w-id="a7bc2d2d-97cc-fdb7-7b3d-7a62a7b1bdec" className="close-button" style={{cursor: "pointer"}} onClick={handleClose}>
            <img src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5eaf350f5c6b7361c922abd3_shapes-and-symbols.svg"
              width="16" alt="" className="image-10" />
          </div>
          <img src="https://global-uploads.webflow.com/5d9527abbaa15ec4815b1c2b/5e45c007ffff38439d8f46da_74306764-4f1bd780-4d18-11ea-8641-5cb1b98392ae.png"
            width="185" alt="" className="image-9" />
          <div dangerouslySetInnerHTML={getBody()}>
          </div>

        </div>
      </Modal>
    </>
  );
};

export default Attention;
