import React from 'react';
import Helmet from 'react-helmet';
import { MetaInformation } from '../../models';

const Meta: React.FC<MetaInformation> = props => {
  return (
    <Helmet>
      <title>Brio Home Health &amp; Hospice: {props.title}</title>
      <meta name="description" content={props.metaDescription} />
      <meta
        name="keywords"
        content={props.metaKeywords ? props.metaKeywords.join(',') : ''}
      />
    </Helmet>
  );
};

export default Meta;
