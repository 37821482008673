import React from 'react';
import Feature from '../components/hospice/Feature';
import {Page} from '../../../@shared/models/Page';
import Meta from '../components/shared/Meta';
import FAQs from '../components/shared/FAQs';
import SectionContent1 from '../components/hospice/SectionContent1';
import Services from '../components/hospice/Services';
import SectionContent2 from '../components/hospice/SectionContent2';
import Video from '../components/hospice/Video';

const Hospice: React.FC<Page> = props => {

  return (
    <>
      <Meta {...props} />
      <Feature title={props.title} description={props.headerDescription} />
      <Video />
      <SectionContent1 />
      <SectionContent2 />
      <Services />
      <div className="hospice-section-accordion">
        <div className="w-container">
          <div className="hospice-faq-image"></div>
          <FAQs title="Frequently Asked Questions" subTitle="For Well-Informed Healthcare Decisions" type="Hospice" />
        </div>
      </div>
    </>
  );
};

export default Hospice;
