import React, {useState} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const Stats: React.FC = () => {
  const [ statsDuration, setStatsDuration ] = useState(3);

  const visibilityOnChange = (isVisible) => {
    console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
    if (isVisible) {
      setStatsDuration(statsDuration + .001);
    }
  }
  return (
    <div className="section-18">
      <div className="div-block-21">
        <VisibilitySensor partialVisibility={true} onChange={visibilityOnChange}>
          <div className="columns-5 w-row">
            <div className="column-15 w-col w-col-2">
              <h1 className="big-numbers green"><CountUp end={100} duration={statsDuration} /></h1>
              <div className="big-number-text">+ Health Care Providers</div>
            </div>
            <div className="column-10 w-col w-col-2">
              <h1 className="big-numbers blue"><CountUp end={14} duration={statsDuration} /></h1>
              <div className="big-number-text">Years of dedicated &amp; reliable care</div>
            </div>
            <div className="column-11 w-col w-col-2">
              <h1 className="big-numbers">0</h1>
              <div className="big-number-text">Deficiencies found on Medicare's 2018 State Survey</div>
            </div>
            <div className="column-12 w-col w-col-2">
              <h1 className="big-numbers green"><CountUp end={24} duration={statsDuration} /></h1>
              <div className="big-number-text">Hours, 7 days a week on-call service</div>
            </div>
            <div className="column-13 w-col w-col-2">
              <h1 className="big-numbers blue"><CountUp end={50} duration={statsDuration} /></h1>
              <div className="big-number-text">Miles radius of Service from Brio's office</div>
            </div>
            <div className="column-14 w-col w-col-2">
              <h1 className="big-numbers"><CountUp end={5} duration={statsDuration} /></h1>
              <div className="big-number-text">Star rating on Home Health Select</div>
            </div>
          </div>
        </VisibilitySensor>
      </div>
    </div>
  );
};
export default Stats;
