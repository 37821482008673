import { Map, Marker, GoogleApiWrapper, IMapProps } from 'google-maps-react';
import React from 'react';

const MapContainer: React.FC<IMapProps> = props => {
  return (
    <div className="map w-widget w-widget-map">
      <Map
        google={props.google}
        zoom={14}
        initialCenter={{ lat: 33.98, lng: -117.71 }}
      >
        <Marker position={{ lat: 33.98572, lng: -117.71654 }} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAFftmhQDDVh3ejfIfn6HV8tIy7BXP4FpQ',
})(MapContainer);
