import React, {ReactElement, useState, useEffect} from 'react';
import axios from 'axios';
import {ApiRoutes} from '../../apiRoutes';
import {EventType} from '../../constants';
import moment from 'moment';
import {BrioEvent} from '../../../../@shared/models/Event';
import Carousel from 'react-material-ui-carousel'

const Announcements: React.FC = () => {
  const [ items, setItems ] = useState<BrioEvent[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${ApiRoutes.Events}?type=${EventType.BrioFamilyAnnouncement}&minDate=${moment().subtract(4, "month").format("YYYY-MM-DD")}`,
      );
      setItems(result.data);
      //window.Webflow?.ready();
    };
    fetchData();
  }, []);

  const createEvent = (item: BrioEvent, index): ReactElement => {
    return (
      <div key={index} className="slide-4 w-slide">
        <h1 className="heading-21">{item.title}</h1>
        <div className="text-block-14">{item.description}</div>
      </div>
    );
  };

  const createAnnouncements = (items: BrioEvent[]): ReactElement => {
    if (!items) {
      return (<div></div>);
    }

    const events = items.sort(function(a, b) {
      return moment(a.date).unix() - moment(b.date).unix();
    });
    return (
      <Carousel autoPlay={true} interval={4000} animation="slide" >
        {events.map((event, index) => createEvent(event, index))}
      </Carousel>
    );
  }

  return (
    <>
      <div className="section-5">
        <div className="hh-section-2-bg special">
          <div className="slider-3 w-slider">
            {
              createAnnouncements(items)
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcements;
